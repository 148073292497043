import http from '@/common/utils/http'

/** 获取下拉框方法 */
export function getSystemSelection(sysId) {
  const data = {
    mainId: sysId
  }
  return http({
    url: `select/getSelectList`,
    method: 'post',
    data
  })
}

