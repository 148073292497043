import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)


/* Layout */
import Layout from '@/pages/layout/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [{
        path: '/login',
        component: () => import('../pages/login/login'),
        hidden: true
    },

    {
        path: '/404',
        component: () => import('../pages/error/index'),
        hidden: true
    },

]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [

    {
        path: '/',
        component: Layout,
        redirect: '/index',
        children: [{
            name: 'index',
            title: '首页',
            path: '/index',
            component: () => import('../pages/home/home'),
            meta: {
                title: '首页',
                icon: 'el-icon-s-home'
            }
        }, ]
    },
    {
        path: '/monitor',
        name: 'monitor',
        meta: {
            title: '远程监控',
            icon: 'el-icon-s-claim',
            roles: ['admin']
        },
        component: Layout,
        children: [{
                path: 'single',
                name: 'single',
                meta: {
                    title: '单梯监控',
                    icon: 'el-icon-s-shop',
                    roles: ['admin']
                },
                component: () => import('../pages/monitor/single')
            },
            {
                path: 'multiple',
                name: '多梯监控',
                meta: {
                    title: '多梯监控',
                    icon: 'el-icon-s-shop',
                    roles: ['admin']
                },
                component: () => import('../pages/monitor/multiple')
            },
            {
                path: 'animation',
                name: '动画展示',
                meta: {
                    title: '动画展示',
                    icon: 'el-icon-s-shop',
                    roles: ['admin']
                },
                component: () => import('../pages/monitor/animation')
            },
        ]
    },
    {
        path: '/maintenance',
        name: 'system',
        meta: {
            title: '维保工单',
            icon: 'el-icon-s-claim',
            roles: ['admin']
        },
        component: Layout,
        children: [{
            path: 'index',
            name: '维保工单',
            meta: {
                title: '维保工单',
                icon: 'el-icon-s-shop',
                roles: ['admin']
            },
            component: () => import('../pages/maintenance/index')
        }]
    },
    {
        path: '/repair',
        name: '急修工单',
        meta: {
            title: '急修工单',
            icon: 'el-icon-s-help',
            roles: ['admin']
        },
        component: Layout,
        children: [{
            path: 'index',
            name: '急修工单',
            meta: {
                title: '急修工单',
                icon: 'el-icon-s-shop',
                roles: ['admin']
            },
            component: () => import('../pages/repair/index')
        }]
    },
    {
        path: '/uncivilized',
        name: '不文明行为',
        meta: {
            title: '不文明行为',
            icon: 'el-icon-s-help',
            roles: ['admin']
        },
        component: Layout,
        children: [{
            path: 'index',
            name: '不文明行为',
            meta: {
                title: '不文明行为',
                icon: 'el-icon-s-shop',
                roles: ['admin']
            },
            component: () => import('../pages/uncivilized/index')
        }]
    },
    {
        path: '/move',
        name: '运行统计',
        meta: {
            title: '运行统计',
            icon: 'el-icon-s-help',
            roles: ['admin']
        },
        component: Layout,
        children: [{
            path: 'index',
            name: '运行统计',
            meta: {
                title: '运行统计',
                icon: 'el-icon-s-shop',
                roles: ['admin']
            },
            component: () => import('../pages/move/index')
        }]
    },
    {
        path: '/Statistics',
        name: '统计分析',
        title: '统计分析',
        meta: {
            title: '统计分析',
            icon: 'el-icon-s-help',
            roles: ['admin']
        },
        component: Layout,
        children: [{
                path: 'fault',
                name: 'fault',
                meta: {
                    title: '故障统计',
                    icon: 'el-icon-s-shop',
                    roles: ['admin']
                },
                component: () => import('../pages/Statistics/fault')
            },
            // {
            //     path: 'Trapped',
            //     name: 'Trapped',
            //     meta: {
            //         title: '困人统计',
            //         icon: 'el-icon-s-shop',
            //         roles: ['admin']
            //     },
            //     component: () => import('../pages/Statistics/Trapped')
            // },
            {
                path: 'uncivilized',
                name: '不文明行文',
                meta: {
                    title: '不文明行为',
                    icon: 'el-icon-s-shop',
                    roles: ['admin']
                },
                component: () => import('../pages/Statistics/uncivilized')
            },
            {
                path: 'move',
                name: '运行数据统计',
                meta: {
                    title: '运行数据统计',
                    icon: 'el-icon-s-shop',
                    roles: ['admin']
                },
                component: () => import('../pages/Statistics/move')
            },
            {
                path: 'history',
                name: '历史走势图',
                meta: {
                    title: '历史走势图',
                    icon: 'el-icon-s-shop',
                    roles: ['admin']
                },
                component: () => import('../pages/Statistics/history')
            }
            ,
            {
                path: 'maintenance',
                name: '分类汇总',
                meta: {
                    title: '分类汇总',
                    icon: 'el-icon-s-shop',
                    roles: ['admin']
                },
                component: () => import('../pages/Statistics/maintenance')
            }
        ]
    },

    // 404 page must be placed at the end !!!
    {
        path: '*',
        redirect: '/404',
        hidden: true
    }
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router