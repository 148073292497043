<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'App',
    data() {
        return {
            scalesNum: 1, // 缩放比例
        }
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        }
    },
    mounted() {
        // 计算缩放比例
        const zoom = () => {
            let width = document.documentElement.clientWidth
            let domBody = document.getElementsByTagName('body')[0]
            if (width > 1920) {
                domBody.style.zoom = 1
            } else {
                domBody.style.zoom = width / 19.2 + '%'
            }

        }
        zoom()
        // 窗口大小变化时，需要重算
        window.addEventListener('resize', zoom, false)
      
        window.document.body.setAttribute("data-theme", this.theme);
    },

    methods: {

    },
    watch:{
        theme(newVal,oldVal) {
            debugger
            if (newVal != oldVal) {
                window.document.body.setAttribute("data-theme", newVal);
            }
        }
    }
}
</script>
<style>
.adaption-box {
    background: red;
    left: 0;
    top: 0;
    position: absolute;
}
</style>